import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { SideMenuStore } from '../../store/sideMenu.state';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.css'
})
export class SideMenuComponent {
  readonly store = inject(SideMenuStore);

  onCreateCommunity() {
  }

    
}
