<div class="bg-background border-solid border-border border-b justify-between flex h-14 w-full">
    <div class="h-full flex items-center">
        <button id="edit-profile" class="lg:hidden" variant="ghost" hlmBtn (click)="store.toggleSelectionMenu()">
            <hlm-icon size="20" name="remixMenu2Line" />
        </button>
        <div>
            <h1 class="text-2xl font-bold mx-4">{{store.selectedFeature()?.name }}</h1>
        </div>
    </div>
    <div class="h-full flex items-center">
        <ng-content #header>
        </ng-content>
    </div>
</div>