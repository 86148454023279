import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-community-avatar',
  templateUrl: './community-avatar.component.html',
  styleUrl: './community-avatar.component.css'
})
export class CommunityAvatarComponent {
  @Input() selected: any;
  @Input() community: any;
}
