import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { catchError, distinctUntilChanged, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UserConfigState } from './userConfig.model';
import * as userConfigActions from './userConfig.actions';
import { initStatus } from 'src/app/store/shared.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { USER_CONFIG_KEY } from 'src/app/store';
import { initUserConfigState } from './userConfig.reducer';
import { selectUserConfigDarkMode, selectUserConfigState } from './userConfig.selector';

@Injectable()
export class UserConfigEffects {
    constructor(
        private store: Store<UserConfigState>,
        private actions$: Actions,
        private translate: TranslateService,
    ) { }

    initUserConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userConfigActions.initUserConfig, ROOT_EFFECTS_INIT),
            mergeMap(async (action) => {
                if (!action) {
                    return userConfigActions.initUserConfigFail({ errorAlert: { message: 'No action', showAlert: false } });
                }
                const userConfig = await Preferences.get({ key: USER_CONFIG_KEY });
                const initState: UserConfigState = userConfig.value ? JSON.parse(userConfig.value) : initUserConfigState
                return userConfigActions.initUserConfigSuccess({ initState });
            }), catchError(async (error) => userConfigActions.initUserConfigFail({ errorAlert: { message: error.message, showAlert: false } }))
        )
    )

    storeUserConfig$ = createEffect(() =>
        this.actions$.pipe(
            filter(action => action.type.startsWith('[UserConfig]')),
            concatLatestFrom(() => this.store.select(selectUserConfigState)),
            tap(([action, userConfigState]) => {
                console.log('storeUserConfig$', action, userConfigState);
                if (userConfigState) {
                    Preferences.set({ key: USER_CONFIG_KEY, value: JSON.stringify(userConfigState) });
                }
            }
            )),
        { dispatch: false }
    );

    initLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userConfigActions.initUserConfigSuccess),
            mergeMap(async ({ initState }) => {
                const currLangCode = navigator.language.slice(0, 2) ?? initState?.language ?? 'en';
                const langs = ['en', 'da', 'de'];
                this.translate.addLangs(langs);
                await this.translate.setDefaultLang(currLangCode);
                const langObj = { language: initState?.language ?? currLangCode ?? langs[0] }
                return userConfigActions.setLanguageSuccess(langObj);
            }),
            catchError((error) => of(userConfigActions.setLanguageFail({ errorAlert: { title: error.message, showAlert: true } }))))
    )

    initDarkMode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userConfigActions.initUserConfigSuccess),
            mergeMap(async ({ initState }) => {
                const darkMode = initState?.theme?.darkMode ?? false;
                return userConfigActions.setDarkMode({ darkMode });
            }),
            catchError((error) => of(error)))
    )

    setLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userConfigActions.setLanguage),
            mergeMap(({ language }) => {
                return this.translate.use(language).pipe(
                    tap(() => console.log('setLanguage', language)),
                    map(() => userConfigActions.setLanguageSuccess({ language })))
            }
            ),
            catchError(async (error) => userConfigActions.setLanguageFail({ errorAlert: { title: error.message, showAlert: true } }))),
    )

    setDarkMode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userConfigActions.setDarkMode),
            concatLatestFrom(() => this.store.select(selectUserConfigDarkMode)),
            switchMap(([{ darkMode }, currentDarkMode]) => {
                if (darkMode !== undefined) {
                    document.body.classList.toggle('dark', darkMode);
                    return of(userConfigActions.setDarkModeSuccess({ darkMode }));
                } else {
                    document.body.classList.toggle('dark', !currentDarkMode);
                    return of(userConfigActions.setDarkModeSuccess({ darkMode: !currentDarkMode }));
                }
            }),
        ))
}
