import { Component } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { Camera, CameraResultType } from '@capacitor/camera';
@Component({
  selector: 'app-create-community',
  templateUrl: './create-community.component.html',
  styleUrl: './create-community.component.css'
})
export class CreateCommunityComponent {

  hideKeyboard(event: Event) {
    console.log('Hiding keyboard', event);

    //check platform
    Keyboard?.hide();
  }

  takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri
    });

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = image.webPath;
  }

}
