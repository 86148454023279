import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.modules';
import { RouterModule } from '@angular/router';
import { CommunityAvatarComponent } from './components/community-avatar/community-avatar.component';
import { CreateCommunityComponent } from './components/create-community/create-community.component';



@NgModule({
    imports: [SharedModule, RouterModule],
    declarations: [CommunityAvatarComponent, CreateCommunityComponent],
    exports: [CommunityAvatarComponent, CreateCommunityComponent],
})
export class CommunityModule { }

