import { UserConfigEffects } from 'src/app/core/user-config/store/userConfig.effects';
import { UserConfigState } from 'src/app/core/user-config/store/userConfig.model';
import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { Type } from "@angular/core";
import { FunctionalEffect } from "@ngrx/effects";
import { RouterEffects } from "../core/router/store/router.effects";
import { isDevMode } from '@angular/core';
import { userConfigReducer } from '../core/user-config/store/userConfig.reducer';
import { Preferences } from '@capacitor/preferences';


export const USER_KEY = '__user';
export const USER_CONFIG_KEY = '__userconfig';
export interface AppState {
    userConfig: UserConfigState;
    router: RouterReducerState,
}

export const effects: (Type<unknown> | Record<string, FunctionalEffect>)[] = [UserConfigEffects, RouterEffects]

export const reducers: ActionReducerMap<AppState> = {
    userConfig: userConfigReducer,
    router: routerReducer,
}


export const metaReducers: MetaReducer<AppState>[] = isDevMode() ? [] : [];



/**
 * This Meta Reducer stores the current states into the ionic local storage whenever it's action has been made
 * @param storage Injecting Storage service into reducer
 * @returns 
 */
export function getMetaReducers(): MetaReducer<AppState> {
    function storing(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
        return (state, action) => {
            const nextState = reducer(state, action);
            if (environment.production) {
                console.log('Storing state', state, action, nextState);
            }
            // if (nextState?.userConfig && action.type.includes("[UserConfig]")) {
            //     // storage.set(USER_CONFIG_KEY, nextState?.userConfig);
            //     console.log('Storing userConfig', nextState?.userConfig);
            //     Preferences.set({ key: USER_CONFIG_KEY, value: JSON.stringify(nextState?.userConfig) });
            // }

            return nextState;
        };
    }
    return storing;
}

