import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.modules';
import { SelectionMenuComponent } from './components/selection-menu/selection-menu.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { CommunityModule } from '../community/community.module';



@NgModule({
    imports: [SharedModule, RouterModule, CommunityModule],
    declarations: [SideMenuComponent, ShellComponent, SelectionMenuComponent],
    exports: [ShellComponent],
})
export class ShellModule { }

