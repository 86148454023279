import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter, withComponentInputBinding } from '@angular/router';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'community1'
  },
  {
    path: '',
    loadChildren: () => import('./core/core.module').then((m) => m.CoreModule)
  },
  {
    path: '',
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
      // preloadingStrategy: PreloadAllModules,
      // useHash: true
    }),
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [provideRouter(routes,
    withComponentInputBinding()
  )],
  exports: [RouterModule, StoreRouterConnectingModule]
})
export class AppRoutingModule { }