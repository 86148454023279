import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TmpService {
    constructor(private http: HttpClient) { }

    fetchCommunities(): Observable<any> {
        const communities = [
            {
                active: true,
                id: "community1",
                name: 'Yuzza',
                description: 'Community 1 description',
                icon: 'remixDashboardLine',
                url: 'community1',
                modules: [
                    {
                        name: 'Bulletin Board',
                        id: "bulletin-board",
                        icon: 'remixDashboardLine',
                        url: 'bulletin-board'
                    },
                    {
                        name: 'Users',
                        id: "users",
                        icon: 'remixUserLine',
                        url: 'users'
                    },
                    {
                        name: 'Posts',
                        id: "posts",
                        icon: 'remixArticleLine',
                        url: 'posts'
                    },
                    {
                        name: 'Comments',
                        id: "comments",
                        icon: 'remixChat1Line',
                        url: 'comments'
                    },
                    {
                        name: 'Settings',
                        id: "settings",
                        icon: 'remixSettingsLine',
                        url: 'settings'
                    },
                ],
                channels: [
                    {
                        name: 'Channel 1',
                        id: "channel_1",
                        icon: 'remixHashtag',
                        url: 'channel1'
                    },
                    {
                        name: 'Channel 2',
                        id: "channel_2",
                        icon: 'remixHashtag',
                        url: 'channel2'
                    },
                    {
                        name: 'Channel 3',
                        id: "channel_3",
                        icon: 'remixHashtag',
                        url: 'channel3'
                    }]
            },
            {
                active: false,
                id: "community2",
                name: 'Community 2',
                description: 'Community 2 description',
                icon: 'icon',
                url: 'community2',
                modules: [
                    {
                        name: 'Bulletin Board',
                        id: "bulletin-board",
                        icon: 'remixDashboardLine',
                        url: 'bulletin-board'
                    },
                    {
                        name: 'Users',
                        id: "users",
                        icon: 'remixUserLine',
                        url: 'users'
                    },
                    {
                        name: 'Posts',
                        id: "posts",
                        icon: 'remixArticleLine',
                        url: 'posts'
                    }
                ],
                channels: [
                    {
                        name: 'Channel 1',
                        id: "channel_1",
                        icon: 'remixHashtag',
                        url: 'channel1'
                    },
                    {
                        name: 'Channel 2',
                        id: "channel_2",
                        icon: 'remixHashtag',
                        url: 'channel2'
                    },
                    {
                        name: 'Channel 3',
                        id: "channel_3",
                        icon: 'remixHashtag',
                        url: 'channel3'
                    }]
            },
            {
                active: false,
                id: "community3",
                name: 'Community 3',
                description: 'Community 3 description',
                icon: 'icon',
                url: 'community3',
                modules: [
                    {
                        name: 'Bulletin Board',
                        id: "bulletin-board",
                        icon: 'remixDashboardLine',
                        url: 'bulletin-board'
                    },
                    {
                        name: 'Users',
                        id: "users",
                        icon: 'remixUserLine',
                        url: 'users'
                    },
                    {
                        name: 'Posts',
                        id: "posts",
                        icon: 'remixArticleLine',
                        url: 'posts'
                    }
                ],
                channels: [
                    {
                        name: 'Channel 1',
                        id: "channel_1",
                        icon: 'remixHashtag',
                        url: 'channel1'
                    },
                    {
                        name: 'Channel 2',
                        id: "channel_2",
                        icon: 'remixHashtag',
                        url: 'channel2'
                    },
                    {
                        name: 'Channel 3',
                        id: "channel_3",
                        icon: 'remixHashtag',
                        url: 'channel3'
                    }]
                
            },
            {
                id: "community4",
                name: 'Community 4',
                description: 'Community 4 description',
                icon: 'icon',
                url: 'community4',
                modules: [
                    {
                        name: 'Bulletin Board',
                        id: "bulletin-board",
                        icon: 'remixDashboardLine',
                        url: 'bulletin-board'
                    },
                    {
                        name: 'Users',
                        id: "users",
                        icon: 'remixUserLine',
                        url: 'users'
                    },
                    {
                        name: 'Posts',
                        id: "posts",
                        icon: 'remixArticleLine',
                        url: 'posts'
                    }
                ],

            },
            {
                id: "community5",
                name: 'Community 5',
                description: 'Community 5 description',
                icon: 'icon',
                url: 'community5'
            },
            {
                id: "community6",
                name: 'Community 6',
                description: 'Community 6 description',
                icon: 'icon',
                url: 'community6'
            },
            {
                id: "community7",
                name: 'Community 7',
                description: 'Community 7 description',
                icon: 'icon',
                url: 'community7'
            },
        ]
        return new Observable((observer) => {
            observer.next(communities);
        });
    }

}
