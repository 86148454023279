import { initStatus } from "src/app/store/shared.model";
import { createReducer, on } from "@ngrx/store";
import * as userConfigActions from './userConfig.actions';
import { UserConfigState } from "./userConfig.model";

export const initUserConfigState: UserConfigState = {
    status: initStatus,
    theme: {
        darkMode: false,
        color: 'primary',
    },
    language: 'en'
};

export const userConfigReducer = createReducer(
    initUserConfigState,
    on(userConfigActions.initUserConfigSuccess, (state, { initState }) => ({ ...state, ...initState, status: { ...initState.status, isLoading: false } })),
    on(userConfigActions.setDarkModeSuccess, (state, { darkMode }) => ({ ...state, theme: { ...state.theme, darkMode } })),
    on(userConfigActions.setColor, (state, { color }) => ({ ...state, theme: { ...state.theme, color } })),
    on(userConfigActions.setLanguageSuccess, (state, { language }) => ({ ...state, language })),
    
);
