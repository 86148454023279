<hlm-dialog>
    <button id="edit-profile" brnDialogTrigger hlmBtn variant="ghost" class="w-full"> <hlm-icon
            class="h-full flex items-center" size="20" name="remixAddLine" /></button>
    <hlm-dialog-content class="sm:max-w-[425px] flex flex-col max-h-lvh box-border" *brnDialogContent="let ctx">
        <hlm-dialog-header>
            <h3 hlmDialogTitle class="text-foreground">Create Community</h3>
            <p hlmDialogDescription>Make changes to your profile here. Click save when you're done.</p>
        </hlm-dialog-header>
        <div class="grid py-4 px-2 text-foreground overflow-auto">
            <div class="grid items-center">
                <label hlmLabel for="name" class="my-4 block">Name
                    <input focusScroll hlmInput type="text" id="name" value="description"
                        class="w-full mt-1.5" /></label>
            </div>
            <div class="grid items-center">
                <label hlmLabel for="description" class="my-4 block">Description
                    <textarea focusScroll hlmInput id="description" value="about"
                        class="block w-full mt-1.5 h-32"></textarea></label>
            </div>
            <div class="grid items-center">
                <label hlmLabel for="category" class="my-4 block">Category
                    <input focusScroll hlmInput type="text" id="category" value="Kollektiv"
                        class="w-full mt-1.5" /></label>
            </div>
            <div class="grid items-center" (click)="takePicture()">
                <button>
                    Add Icon
                </button>
            </div>
        </div>
        <hlm-dialog-footer>
            <button hlmBtn type="submit">Save changes</button>
        </hlm-dialog-footer>
    </hlm-dialog-content>
</hlm-dialog>