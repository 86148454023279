<div class="w-full flex content-center flex-col items-center cursor-pointer">
    <div class="rounded-md w-10 h-10 "
 
        >
        <div class="text-center w-full h-full flex justify-center items-center ">
            <p class="text-base truncate">
                {{community.name.slice(0, 2)}}
            </p>
        </div>
    </div>
</div>