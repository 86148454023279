import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[focusScroll]'
})
export class FocusScrollDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('focus')
  onFocus(): void {
    this.scrollToElement();
  }

  private scrollToElement(): void {
    const element = this.el.nativeElement;
    let previousElement = this.renderer.parentNode(element);

    // Checking if the previous element is indeed a label
    if (previousElement && previousElement.tagName === 'LABEL') {
      // Scrolling the label into view
      previousElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // Fallback: scroll the input itself into view
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
