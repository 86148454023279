import { Component, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { SideMenuStore } from './store/sideMenu.state';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  providers: [SideMenuStore]
})
export class ShellComponent {
  readonly store = inject(SideMenuStore);

}
