import { Component, inject } from '@angular/core';
import { SideMenuStore } from 'src/app/core/shell/store/sideMenu.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  readonly store = inject(SideMenuStore);
  
}
