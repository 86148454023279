<div class="h-full w-20 border-r border-solid border-border py-2 bg-background" *ngIf="communities">
    <div class="h-full w-full flex items-center flex-col justify-between">
        <div id="top-side-menu" class="flex flex-col items-center w-full">
            <ng-container *ngFor="let community of communities">
                <app-community-avatar class="my-1 rounded-md" [community]="community" [routerLink]="[community.id]"
                    routerLinkActive="active"></app-community-avatar>
            </ng-container>
            <!-- <brn-separator hlmSeparator /> -->
            <app-create-community></app-create-community>
        </div>
        <div id="bot-side-menu" class="w-full flex flex-col items-center">
            <div class="cursor-pointer my-1  rounded-md w-10 h-10">
                <div class="text-center w-full h-full flex justify-center items-center ">
                    <hlm-icon size="20" name="remixLightbulbLine" />
                </div>
            </div>
            <div class="cursor-pointer my-1  rounded-md w-10 h-10">
                <div class="text-center w-full h-full flex justify-center items-center ">
                    <hlm-icon size="20" name="remixChat1Line" />
                </div>
            </div>
            <div [routerLink]="['/user-config']" routerLinkActive="active"
                class="cursor-pointer my-1  rounded-md w-10 h-10">
                <div class="text-center w-full h-full flex justify-center items-center ">
                    <hlm-icon size="20" name="remixUser3Line" />
                </div>
            </div>
            <div class="w-full">
                <div class="overflow-hidden">
                    <span class="text-xs">
                        {{ version }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>