<div class="flex h-full text-sm">
    <div [class.opacity-0]="!store.isSelectionMenuOpen()" [class.opacity-50]="store.isSelectionMenuOpen()"
        [class.pointer-events-none]="!store.isSelectionMenuOpen()" (click)="store.toggleSelectionMenu()"
        class="fixed inset-0 z-10 lg:hidden bg-black transition-opacity duration-300" aria-hidden="true">
    </div>
    <aside tabindex="-1" (swipeLeft)="store.toggleSelectionMenu()"
        (swipeRight)="store.toggleDefaultMenu(store.selectedCommunity())"
        class="flex lg:hidden transform transition-transform fixed bg-background inset-y-0 z-30 h-full duration-500 ease-in-out"
        [class.-translate-x-full]="!store.isSelectionMenuOpen()" [class.translate-x-0]="store.isSelectionMenuOpen()">
        {{ store.selectedCommunity | json }}
        <app-selection-menu [communities]="store.communities()"
            (createCommunity)="onCreateCommunity()"></app-selection-menu>
    </aside>

    <aside class="hidden lg:flex">
        <app-selection-menu [communities]="store.communities()"
            (createCommunity)="onCreateCommunity()"></app-selection-menu>
    </aside>
</div>