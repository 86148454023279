import { createAction, props } from "@ngrx/store";
import { ErrorAlert, SuccessAlert } from "src/app/store/shared.model";
import { UserConfigState } from "./userConfig.model";

export const initUserConfig = createAction(
    '[UserConfig] Init UserConfig',
);

export const initUserConfigSuccess = createAction(
    '[UserConfig] Init UserConfig Success',
    props<{ initState: UserConfigState, successAlert?: SuccessAlert }>()
);

export const initUserConfigFail = createAction(
    '[UserConfig] Init UserConfig Fail',
    props<{ errorAlert: ErrorAlert }>()
);

export const setDarkMode = createAction(
    '[UserConfig] Set DarkMode',
    props<{ darkMode?: boolean }>()
);

export const setDarkModeSuccess = createAction(
    '[UserConfig] Set DarkMode Success',
    props<{ darkMode: boolean }>()
);

export const setDarkModeFail = createAction(
    '[UserConfig] Set DarkMode Fail',
    props<{ errorAlert: ErrorAlert }>()
);

export const setColor = createAction(
    '[UserConfig] Set Color',
    props<{ color: string }>()
);

export const setLanguage = createAction(
    '[UserConfig] Set Language',
    props<{ language: string }>()
);

export const setLanguageSuccess = createAction(
    '[UserConfig] Set Language Success',
    props<{ language: string }>()
);

export const setLanguageFail = createAction(
    '[UserConfig] Set Language Fail',
    props<{ errorAlert: ErrorAlert }>()
);
