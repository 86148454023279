
export interface ErrorAlert {
    title?: string,
    message?: string,
    error?: any,
    showAlert?: boolean
}


export interface SuccessAlert {
    title?: string,
    message?: string,
    showAlert?: boolean
}


export interface Status {
    isLoading: boolean;
    loadingMessage: string;
}

export const initStatus: Status = {
    isLoading: false,
    loadingMessage: ''
}
