import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TimeagoModule } from "ngx-timeago";
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import {
    BrnSheetCloseDirective,
    BrnSheetComponent,
    BrnSheetContentDirective,
    BrnSheetDescriptionDirective,
    BrnSheetOverlayComponent,
    BrnSheetTitleDirective,
    BrnSheetTriggerDirective,
} from '@spartan-ng/ui-sheet-brain';
import {
    HlmSheetCloseDirective,
    HlmSheetContentDirective,
    HlmSheetDescriptionDirective,
    HlmSheetFooterComponent,
    HlmSheetHeaderComponent,
    HlmSheetOverlayDirective,
    HlmSheetTitleDirective,
} from '@spartan-ng/ui-sheet-helm';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { provideIcons } from '@ng-icons/core';
import {
    remixHashtag,
    remixMenu2Line, remixUser3Line, remixCloseLine, remixLightbulbLine,
    remixSettingsLine, remixDashboardLine, remixCalendar2Line, remixAddLine, remixUserLine, remixArticleLine, remixChat1Line,
    remixGroupLine, remixToolsLine
} from '@ng-icons/remixicon';
import { HlmLabelDirective } from '@spartan-ng/ui-label-helm';
import { HlmSeparatorDirective } from "./ui/ui-separator-helm/src/lib/hlm-separator.directive";
import { BrnSeparatorComponent } from '@spartan-ng/ui-separator-brain';
import { BrnCommandModule } from '@spartan-ng/ui-command-brain';
import { HlmCommandModule } from '@spartan-ng/ui-command-helm';
import {
    BrnPopoverComponent,
    BrnPopoverContentDirective,
    BrnPopoverTriggerDirective,
} from '@spartan-ng/ui-popover-brain';
import { BrnSwitchComponent, BrnSwitchThumbComponent } from '@spartan-ng/ui-switch-brain';
import { HlmPopoverContentDirective } from '@spartan-ng/ui-popover-helm';
import { NgForOf } from '@angular/common';
import { HlmScrollAreaComponent } from "./ui/ui-scrollarea-helm/src/lib/hlm-scroll-area.component";
import { HlmAspectRatioDirective } from "./ui/ui-aspectratio-helm/src/lib/helm-aspect-ratio.directive";
import { HlmAvatarFallbackDirective } from "./ui/ui-avatar-helm/src/lib/fallback";
import { HlmAvatarComponent } from "./ui/ui-avatar-helm/src/lib/hlm-avatar.component";
import { HlmAvatarImageDirective } from "./ui/ui-avatar-helm/src/lib/image";
import { HlmDialogTitleDirective } from "./ui/ui-dialog-helm/src/lib/hlm-dialog-title.directive";
import { HlmDialogHeaderComponent } from "./ui/ui-dialog-helm/src/lib/hlm-dialog-header.component";
import { HlmDialogFooterComponent } from "./ui/ui-dialog-helm/src/lib/hlm-dialog-footer.component";
import { HlmDialogContentComponent } from "./ui/ui-dialog-helm/src/lib/hlm-dialog-content.component";
import { HlmDialogComponent } from "./ui/ui-dialog-helm/src/lib/hlm-dialog.component";
import { HlmDialogDescriptionDirective } from "./ui/ui-dialog-helm/src/lib/hlm-dialog-description.directive";
import { BrnDialogContentDirective, BrnDialogTriggerDirective } from "@spartan-ng/ui-dialog-brain";
import { HeaderComponent } from "./components/header/header.component";
import { DefaultSideMenuComponent } from "./components/default-side-menu/default-side-menu.component";
import { FocusScrollDirective } from "./directives/FocusScrollDirective";
import { HlmSwitchDirective } from "./ui/ui-switch-helm/src/lib/hlm-switch.directive";
import { HlmSwitchThumbDirective } from "./ui/ui-switch-helm/src/lib/hlm-switch-thumb.directive";


export class AppModule { }

registerLocaleData(localeDE);
registerLocaleData(localeDE);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const sharedComponents = [
    HeaderComponent,
    DefaultSideMenuComponent,
    FocusScrollDirective
]
const icons = {
    remixHashtag,
    remixMenu2Line, remixUser3Line, remixCloseLine, remixLightbulbLine, remixGroupLine, remixToolsLine,
    remixSettingsLine, remixDashboardLine, remixCalendar2Line, remixAddLine, remixUserLine, remixArticleLine, remixChat1Line
}

const hlmModules = [
    BrnSwitchComponent,
    BrnSwitchThumbComponent,
    HlmSwitchDirective,
    HlmSwitchThumbDirective,
    HlmAvatarImageDirective,
    HlmAvatarComponent,
    HlmAvatarFallbackDirective,
    HlmScrollAreaComponent,
    HlmAspectRatioDirective,
    BrnCommandModule,
    HlmCommandModule,
    BrnSheetComponent,
    BrnSheetOverlayComponent,
    BrnSheetTriggerDirective,
    BrnSheetContentDirective,
    BrnSheetCloseDirective,
    BrnSheetTitleDirective,
    BrnSheetDescriptionDirective,
    HlmSheetOverlayDirective,
    HlmSheetContentDirective,
    HlmSheetHeaderComponent,
    HlmSheetFooterComponent,
    HlmSheetTitleDirective,
    HlmSheetDescriptionDirective,
    HlmSheetCloseDirective,
    HlmButtonDirective,
    HlmInputDirective,
    HlmIconComponent,
    HlmLabelDirective,
    HlmSeparatorDirective,
    BrnSeparatorComponent,
    BrnPopoverComponent,
    BrnPopoverTriggerDirective,
    HlmPopoverContentDirective,
    BrnPopoverContentDirective,
    NgForOf,
    BrnDialogTriggerDirective,
    BrnDialogContentDirective,
    HlmDialogComponent,
    HlmDialogContentComponent,
    HlmDialogHeaderComponent,
    HlmDialogFooterComponent,
    HlmDialogTitleDirective,
    HlmDialogDescriptionDirective,
    HlmLabelDirective,
    HlmInputDirective,
    HlmButtonDirective,

]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        StoreRouterConnectingModule,
        ReactiveFormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ...hlmModules        
    ],
    providers: [provideIcons(icons)],
    declarations: [...sharedComponents],
    exports: [
        CommonModule,
        TimeagoModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        StoreRouterConnectingModule,
        ...hlmModules,
        ...sharedComponents,
        HttpClientModule,
    ]
})
export class SharedModule { }