import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Logger } from './core/services/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    if (environment.production) {
      Logger.enableProductionMode();
    }
  }

  onActivate(event: any) {
    console.log('onActivate', event); 
  }

}
