// create a sample router ngrx effect file

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { RouterState, ROUTER_NAVIGATION, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { catchError, mergeMap } from 'rxjs';

@Injectable()
export class RouterEffects {
    constructor(
        private store: Store<RouterState>,
        private actions$: Actions,
        private router: Router,
        private translate: TranslateService,
    ) { }

    initCourses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            mergeMap(async (action) => {
                console.log('ROUTER_NAVIGATED', action);
                this.router
            }),
        ), { dispatch: false }
    )
}
