import { Component, EventEmitter, Input, Output} from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-selection-menu',
  templateUrl: './selection-menu.component.html',
  styleUrl: './selection-menu.component.css'
})
export class SelectionMenuComponent {
  @Input() communities: any;
  @Output() createCommunity: EventEmitter<any> = new EventEmitter<any>();

  public version = environment.version;
  
  constructor() { }

  onCreateCommunity() {
    this.createCommunity.emit();
  }

}
