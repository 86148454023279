<div class="flex h-full text-sm bg-background">
    <aside tabindex="-1" (swipeLeft)="store.toggleSelectionMenu()"
        class="bg-background flex lg:hidden transform transition-transform fixed inset-y-0 z-20 h-full duration-700 ease-in-out"
        [class.-translate-x-full]="!store.isDefaultMenuOpen()" [class.translate-x-20]="store.isDefaultMenuOpen()">
        <button class="absolute right-0 top-0 p-2" hlm (click)="store.toggleSelectionMenu()">
            <hlm-icon class="" size="25" name="remixCloseLine" />
        </button>
        <div class="w-60 pt-6 h-full flex flex-col justify-between border-r border-border">
            <ng-content select="[base]"></ng-content>
        </div>
    </aside>
    <aside class="hidden lg:flex">
        <div class="w-60 pt-6 h-full flex flex-col justify-between border-r border-border">
            <ng-content select="[lg]"></ng-content>
        </div>
    </aside>
</div>