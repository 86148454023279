import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserConfigState } from "./userConfig.model";

export const selectUserConfigState = createFeatureSelector<
    Readonly<UserConfigState>
>('userConfig');

export const selectUserConfigStatus = createSelector(
    selectUserConfigState,
    (state: UserConfigState) => state?.status
);

export const selectUserConfigLanguage = createSelector(
    selectUserConfigState,
    (state: UserConfigState) => state?.language
);



export const selectUserConfigDarkMode = createSelector(
    selectUserConfigState,
    (state: UserConfigState) => state?.theme?.darkMode
);
