// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

export const environment = {
  production: true,
  sentryDsn: 'https://cccbbd5b3442a7274807e38bc5a698f1@o4506621796745216.ingest.sentry.io/4506627689807872',
  serverUrl: '',
  defaultLanguage: 'en-US',
  supportedLanguages: ['de-DE', 'en-US'],
  version: 'w--beta'
};
