
import { computed, inject } from '@angular/core';
import { signalStore, patchState, withState, withMethods, withComputed, withHooks } from '@ngrx/signals';
import { firstValueFrom } from 'rxjs';
import { TmpService } from './tmp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouterState } from '@ngrx/router-store';
import { selectRouteNestedParams } from '../../router/store/router.selector';


export interface SideMenuState {
    loading: boolean;
    isSelectionMenuOpen: boolean;
    isDefaultMenuOpen: boolean;
    selectedCommunityId: string;
    selectedFeatureId: string;
    communities: any;
}

export const initialState: SideMenuState = {
    isSelectionMenuOpen: false,
    isDefaultMenuOpen: false,
    loading: false,
    selectedCommunityId: '1',
    selectedFeatureId: '1',
    communities: []
};

export const SideMenuStore = signalStore(
    withState(initialState),
    withComputed(({ selectedCommunityId, communities, selectedFeatureId }) => ({
        selectedCommunity: computed(() => communities().find((community: any) => community.id === selectedCommunityId())),
        selectedFeature: computed(() => (communities().find((community: any) => community.id === selectedCommunityId())?.modules?.find((feature: any) => feature.id === selectedFeatureId()))),
    })),
    withMethods(({ isSelectionMenuOpen, isDefaultMenuOpen, selectedCommunityId,  ...store }, communityService = inject(TmpService),
        router = inject(Router)) => ({
            toggleSelectionMenu(community?: any) {
                const newState = !isSelectionMenuOpen();

                patchState(store, {
                    isSelectionMenuOpen: newState,
                    isDefaultMenuOpen: newState && selectedCommunityId() !== ''
                });
            },
            toggleDefaultMenu(community?: any) {
                const newState = ((!isDefaultMenuOpen() || community?.id !== selectedCommunityId()) && isSelectionMenuOpen());
                patchState(store, {
                    isDefaultMenuOpen: newState,
                });
            },
            async getCommunities() {
                patchState(store, { loading: true });
                const communities = await firstValueFrom(communityService.fetchCommunities());
                patchState(store, { communities, loading: false });
            }
        })),
    withHooks({

        onInit({ getCommunities, ...store }, routerState = inject(Store<RouterState>)) {
            getCommunities();
            console.log(routerState)
            const routerSub = routerState.select(selectRouteNestedParams).pipe(
            ).subscribe(({ selectedCommunityId, selectedFeatureId }) => {
                patchState(store, {
                    selectedCommunityId,
                    selectedFeatureId
                });
            });

            // router.routerState.subscribe((params: any) => {
            //     const communityId = params['communityId']; // Use the correct parameter key
            //     const featureId = params['featureId'];     // Use the correct parameter key

            //     console.log(params, communityId, featureId, "e")
            //     // Update the state with the route parameters
            //     patchState(store, {
            //         selectedCommunityId: communityId,
            //         selectedFeatureId: featureId
            //     });
            // });

        },
        onDestroy() {
            console.log('onDestroy')

        },
    }

    )
);

export const rootRoute = (route: ActivatedRoute): ActivatedRoute => {
    while (route.firstChild) {
        console.log(route)
        route = route.firstChild;
    }
    return route;
}