import { Component, inject } from '@angular/core';
import { SideMenuStore } from 'src/app/core/shell/store/sideMenu.state';

@Component({
  selector: 'app-default-side-menu',
  templateUrl: './default-side-menu.component.html',
  styleUrl: './default-side-menu.component.css'
})
export class DefaultSideMenuComponent {
  readonly store = inject(SideMenuStore)

  // selectFeature(feature: any) {
  //   console.log('selectFeature', feature)
  //   this.store.selectFeature(feature);
  // }

  // selectChannel(channel: any) {
  //   console.log('selectChannel', channel)
  //   this.store.selectFeature(channel);
  // }
}
